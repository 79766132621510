<template>
  <div class="page page--vacancy">
    <section class="section section--gray" id="sec1" data-sec="1">
      <div class="container">
        <div class="row">
          <div class="col-12 px-xl-5">
            <router-link tag="a" :to="`/${$i18n.locale}` + '/careers'" class="link link--arrow link--td-none">
              <i class="ico ico--ui-arrow">
                <img src="/img/icons/ui/ico-any-arrow-left-blue.svg" alt="arrow">
              </i>
              <span class="link fs-16 fs-md-20 fs-xl-24 tc-blue">{{ cc.btn[0].text }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <template v-if="key !== 'none'">
      <section class="section section--gray" id="sec2" data-sec="2">
        <div class="container">
          <div class="row">
            <div class="col-12 px-xl-5">
              <div class="offer">
                <div class="offer__head">
                  <div class="wrapper d-flex align-items-start justify-content-between mb-3 mb-lg-0">
                    <h2 class="title fs-20 fs-sm-28 fs-md-42 fw-600 lh-13 pe-4 pe-lg-5 mt-sm-2 mt-md-0 mt-lg-1">{{ cc.vacancies[key].title }}</h2>
                    <a :href="cc.btn[1].to" v-smooth-scroll="{ duration: 1000, offset: -130 }" class="btn btn-3-blue btn-w-140 btn-w-lg-200 btn-h-52 btn-h-lg-66 d-none d-sm-flex"><span>{{ cc.btn[1].text }}</span></a>
                  </div>
                  <p class="text tc-silver fs-16 fs-sm-24 fw-300 lh-20 lh-md-17">{{ cc.vacancies[key].group }}, {{ cc.vacancies[key].location }}</p>
                  <p class="text tc-silver fs-14 fs-sm-18 fw-300 lh-20 lh-md-17 mb-3 mb-lg-0">{{ cc.vacancies[key].type }}</p>
                  <a :href="cc.btn[1].to" v-smooth-scroll="{ duration: 1000, offset: -100 }" class="btn btn-3-blue btn-w-lg-200 btn-h-52 btn-h-lg-66 d-sm-none mx-auto"><span>{{ cc.btn[1].text }}</span></a>
                </div>
                <hr class="hr hr-silver my-43 my-md-5" />
                <div class="offer__body">
                  <div class="offer__body-item"
                       v-for="(item, i) in cc.vacancies[key].description" :key="'offer-item-' + i">
                    <p class="text fs-20 fs-sm-24 fw-500 lh-20 lh-md-17 mb-1 mb-lg-4">{{ item.title }}</p>
                    <ul class="offer__list">
                      <li v-for="(li, l) in item.list" :key="'offer-item-' + 'i' + '-list-' + l">
                        <span></span>
                        <span class="tc-silver fs-14 fs-sm-18">{{ li }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="section section--gray" id="sec3" data-sec="3">
      <div class="container">
        <div class="row">
          <div class="col-12 px-xl-5">
            <div class="offer">
              <div class="offer__loader" :class="{'active': isLoading}">
                <div class="offer__loader-wrapper">
                  <div class="offer__loader-icon">
                    <img src="/img/loader.gif" alt="loader">
                  </div>
                </div>
              </div>
              <div class="offer__response" :class="{'active': isSended}">
                <div class="offer__response-wrapper">
                  <h2 class="title fs-32 fs-sm-50 fs-md-42 fs-xl-50 fw-600 lh-13 ta-center mb-5">Thank you for your interest!</h2>
                  <p class="text tc-silver fs-16 fs-sm-24 fw-300 ta-center">Your message was sent successfully!</p>
                  <p class="text tc-silver fs-16 fs-sm-24 fw-300 ta-center">We will contact you soon.</p>
                </div>
              </div>
              <h2 class="title fs-32 fs-sm-50 fs-md-42 fs-xl-58 fw-600 lh-13 mb-3 mb-md-5">{{ cc.title }}</h2>
              <form class="form" @submit.prevent="submitHandler()" enctype="multipart/form-data">
                <div class="row mb-4 pb-xl-3">
                  <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <div class="input input--text">
                      <label for="f-name" class="text fs-14 fs-sm-24 fw-500 lh-20 lh-md-17 mb-1 mb-md-3">{{ fc.label.fullName }}</label>
                      <input type="text" id="f-name" v-model="fieldName"
                             :class="[{invalid: ($v.fieldName.$invalid && $v.fieldName.$dirty)}]"/>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="input input--text">
                      <label for="f-email" class="text fs-14 fs-sm-24 fw-500 lh-20 lh-md-17 mb-1 mb-md-3">{{ fc.label.email }}</label>
                      <input type="email" id="f-email" placeholder="example@email.com"
                             v-model="fieldEmail"
                             :class="[{invalid: ($v.fieldEmail.$invalid && $v.fieldEmail.$dirty)}]"/>
                    </div>
                  </div>
                </div>
                <div class="row mb-4 pb-xl-3">
                  <div class="col-12">
                    <div class="input input--file">
                      <label class="text fs-14 fs-sm-24 fw-500 lh-20 lh-md-17 mb-1 mb-md-3">{{ fc.label.attach }}</label>
                      <div class="file">
                        <label for="f-file">{{ fileData.name !== '' ? fileData.name + ', ' + fileData.size : fc.label.choose }}</label>
                        <input type="file" name="file-cv" id="f-file" @change="fileChanged($event)">
                        <label for="f-file" class="btn btn-3-blue btn-w-md-200 btn-h-52 btn-h-md-66"><span>{{ cc.btn[2].text }}</span></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-4 mb-md-5">
                  <div class="col-12">
                    <div class="input input--textarea">
                      <label class="text fs-14 fs-sm-24 fw-500 lh-20 lh-md-17 mb-1 mb-md-3">{{ fc.label.textarea }}</label>
                      <div class="textarea">
                        <textarea name="" id="f-textarea" v-model="fieldMessage"
                                  :class="{contains: fieldMessage.length > 0}"></textarea>
                        <label for="f-textarea">{{ fc.label.typeHere }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-lg-6 mb-4 mb-lg-0">
                    <div class="wrapper d-flex align-items-center">
                      <div class="checkbox">
                        <input type="checkbox" id="f-checkbox" v-model="fieldCheckbox">
                        <label for="f-checkbox" :class="{'invalid': (!fieldCheckbox && isDirty)}"></label>
                        <label for="f-checkbox" class="tc-silver fs-14 fs-sm-20 fw-300 lh-20 lh-md-17">{{ fc.tnc[0] }} <a href="" class="tc-blue">{{ fc.tnc[1] }}</a></label>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-lg-6">
                    <button type="submit" class="btn btn-3-blue btn-w-md-300 btn-w-xl-300 btn-h-52 btn-h-md-66 mx-md-auto mx-lg-0 ms-lg-auto"><span>{{ cc.btn[3].text }}</span></button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
export default {
  data: () => ({
    fieldName: '',
    fieldEmail: '',
    fieldMessage: '',
    fieldCheckbox: false,
    fileData: {
      name: '',
      size: ''
    },
    isLoading: false,
    isDirty: false,
    isSended: false
  }),
  validations: {
    fieldName: {
      required,
      minLength: minLength(2)
    },
    fieldEmail: {
      required,
      email
    }
  },
  methods: {
    fileChanged (e) {
      console.log(e.target)
      const name = e.target.files[0].name
      const size = e.target.files[0].size / 1e+6
      this.fileData.name = name
      this.fileData.size = Number(Math.round(size + 'e' + 3) + 'e-' + 3) + 'Mb'
    },
    submitHandler () {
      this.isDirty = true
      if (this.$v.$invalid && !this.fieldCheckbox) {
        this.$v.$touch()
        this.isLoading = false
      } else {
        this.isLoading = true
        const fieldFile = document.querySelector('input[name="file-cv"]')
        const fields = [
          ['fieldVacancy', this.vacancy],
          ['fieldName', this.fieldName],
          ['fieldEmail', this.fieldEmail],
          ['fieldMessage', this.fieldMessage],
          ['fieldFile', fieldFile.files[0]]
        ]
        const data = new FormData()
        fields.forEach((item, i) => {
          data.append(item[0], item[1])
        })
        fetch('https://virarosi.com/vendor/scripts/email/send.php', {
          method: 'POST',
          body: data
        }).then((response) => {
          if (response.ok) {
            this.isLoading = false
            this.isSended = true
            if (window.rc && window.rc.conversionHit) {
              window.rc.conversionHit({ id: 100118479, seznam_value: 250 })
            }
          }
        }).then((json) => {
          console.log(json)
        }).catch((error) => {
          console.log(error)
          this.isLoading = false
        })
      }
    }
  },
  computed: {
    key () {
      return this.$store.state.vacancy
    },
    vacancy () {
      return this.key !== 'none' ? this.cc.vacancies[this.key].title : 'Any'
    },
    cc () {
      return this.$t('pages.vacancy')
    },
    fc () {
      return this.$t('form')
    }
  },
  mounted () {
    window.scrollTo(0, 0)
    const seznamScriptCode = document.createElement('script')
    seznamScriptCode.setAttribute('src', 'https://virarosi.com/vendor/scripts/vacancy/seznamOptions.js')
    seznamScriptCode.async = true
    document.head.appendChild(seznamScriptCode)
    const seznamScriptSrc = document.createElement('script')
    seznamScriptSrc.setAttribute('src', 'https://www.seznam.cz/rs/static/rc.js')
    seznamScriptSrc.async = true
    document.head.appendChild(seznamScriptSrc)
  }
}
</script>
